// normalize CSS across browsers
// import "./src/css/normalize.css"

// custom CSS styles
// import "./src/css/style.css"

import "./src/css/global.css"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
